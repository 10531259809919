import Vue from 'vue'
import App from '@/App.vue'
import NotFound from '@/NotFound.vue'
import router from '@/router'
import store from '@/store'

// Plugin
import _ from 'lodash'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import 'dayjs/locale/zh-tw'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'

import Meta from 'vue-meta'
import vueLazy from 'vue-lazyload'
import multiSelect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import VCalendar from 'v-calendar'
import clickOutside from '@/directives/clickOutside'
import VueNumericInput from 'vue-numeric-input'
import VModal from 'vue-js-modal'
import numeral from 'numeral'
import tippy from 'tippy.js'
import 'tippy.js/index.css'
import VueTippy from 'vue-tippy'
import VueScrollactive from 'vue-scrollactive'
import VueMatomo from 'vue-matomo'
import VueGtag from 'vue-gtag'
import SimpleVueValidation from 'simple-vue-validator'

// import 'swiper/dist/css/swiper.min.css'

import i18n from '@/i18n'

import * as currencyConvert from '@/utils/currencyConvert'
import convertI18nObj from '@/utils/convertI18nObj'

import 'owlsight/dist/main.css'
import 'owlsight/icons/css/owl.css'
import 'owlsight/icons/css/animation.css'

// App Stylesheets
import '@/stylesheets/main.sass'

// Sentry
import * as Sentry from '@sentry/browser'
import { Vue as VueIntegration } from '@sentry/integrations'
import { Integrations } from '@sentry/tracing'

// vector gtag list
import vectorGtagList from '@/assets/vectorGtagList.js'

// cookie module
import $cookies from 'js-cookie'

Sentry.init({
  dsn: 'https://212fb005ab9f45b2b13bf177898a193c@sentry.owlting.com/14',
  environment: process.env.NODE_ENV,
  integrations: [
    new VueIntegration({
      Vue,
      tracing: true
    }),
    new Integrations.BrowserTracing()
  ],
  ignoreErrors: [/( Cancel: )/],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0
})

const isProduction = process.env.NODE_ENV === 'production'

Vue.config.productionTip = false

Vue.use(VueScrollactive)

Vue.use(Meta, {
  keyName: 'metaInfo', // the component option name that vue-meta looks for meta info on.
  attribute: 'data-vue-meta', // the attribute name vue-meta adds to the tags it observes
  ssrAttribute: 'data-vue-meta-server-rendered', // the attribute name that lets vue-meta know that meta info has already been server-rendered
  tagIDKeyName: 'vmid' // the property name that vue-meta uses to determine whether to overwrite or append a tag
})

dayjs.extend(isSameOrAfter)
dayjs.locale('zh-tw')

Object.defineProperty(Vue.prototype, '$dayjs', { value: dayjs })
Object.defineProperty(Vue.prototype, '$numeral', { value: numeral })
Object.defineProperty(Vue.prototype, '$tippy', { value: tippy })
Object.defineProperty(Vue.prototype, '$convertI18nObj', { value: convertI18nObj })

Vue.use(VueTippy, {
  theme: 'light',
  duration: 300,
  animateFill: false,
  animation: 'shift-toward'
})

Vue.component('multiSelect', multiSelect)

Vue.filter('dayFormat', function (date, format = 'YYYY/MM/DD') {
  return dayjs(date).format(format)
})

Vue.filter('numeral', function (price, format = '0,0[.]00') {
  const thisCurrency = _.find(store.state.currencyExist, currency => {
    return currency.code === store.state.selectedCurrency
  })
  const integerCurrency = ['TWD', 'JPY']
  if (integerCurrency.includes(thisCurrency?.code)) {
    format = '0,0'
  }
  return numeral(price).format(format)
})

Vue.filter('currency', function (value, options) {
  return currencyConvert.convert(value, options)
})

Vue.filter('contentParser', function (data) {
  if (data && isJSON(data) && data.startsWith('{')) {
    const object = JSON.parse(data)
    const snake = _.snakeCase(store.state.selectedLanguage)
    if (_.includes(['zh_tw', 'zh_hant', 'zh_hans'], snake)) {
      if (typeof object.tw === 'undefined') {
        const available = Object.keys(object)[0]
        return parseJSON(object[available])
      }
      return parseJSON(object.tw)
    }
    if (object[store.state.selectedLanguage]) {
      if (typeof object[store.state.selectedLanguage] === 'undefined') {
        const available = Object.keys(object)[0]
        return parseJSON(object[available])
      }
      return parseJSON(object[store.state.selectedLanguage])
    } else {
      const available = Object.keys(object)[0]
      return parseJSON(object[available])
    }
  } else {
    return parseJSON(data)
  }
})

const isJSON = (data = '') => {
  if (typeof data !== 'string') return false

  const str = data.replace(/\n/g, '')
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

const parseJSON = data => {
  if (isJSON(data)) return JSON.parse(data)
  else return data
}

Vue.directive('clickOutside', clickOutside)
Vue.use(VCalendar, {
  firstDayOfWeek: 1,
  locale: store.state.selectedLanguage
})
Vue.use(VueNumericInput)
Vue.use(VModal, {
  dialog: true,
  dynamic: true,
  injectModalsContainer: true
})
Vue.use(vueLazy, {
  // loading: 'https://loading.io/spinners/lava-lamp/index.lava-lamp-preloader.svg',
  observer: true,
  observerOptions: {
    rootMargin: '0px',
    threshold: 0.1
  }
})

Vue.use(SimpleVueValidation)

Vue.use(VueMatomo, {
  // Configure your matomo server and site by providing
  host: 'https://matomo.owlting.com/',
  siteId: 5,

  // Enables automatically registering pageviews on the router
  router: router,
  debug: !isProduction
})
const url = new URL(window.location)
const hostName = url.host
const hotelUUID = url.pathname.slice(1).split('/')[0]
const owlNestGaId = 'G-8ZK4DGX718'
const owlNestGaAwId = 'AW-10824892631'
const isOwlTingDomain = hostName.includes('.owlting.')

if (!isOwlTingDomain && !hotelUUID) {
  // custom domain w/o hotelUUID -> redirect to '/booking'
  window.location.replace(`${window.location.href}booking`)
}
store.commit('SET_IS_OWLTING_DOMAIN', isOwlTingDomain)

const gtagConfig = {
  config: {
    id: owlNestGaId
  },
  includes: [
    { id: owlNestGaAwId }
  ]
}

store.dispatch('hotel/fetchHotel', hotelUUID)
  .then(() => {
    // gtag init
    const hotelGaIds = store.getters['hotel/gaIds']
    const oneHour = 1 / 24

    if (hotelGaIds) {
      hotelGaIds.forEach(gaId => {
        gtagConfig.includes.push({ id: gaId })
      })
    }

    // vector gtag
    const hotelId = store.getters['hotel/hotel'].id
    const vectorGtagHotel = vectorGtagList.find(hotel => hotel.id === hotelId)
    const sourceFromQuery = new URL(window.location.href).searchParams.get('source')
    const sourceFromCookies = $cookies.get('source')

    if ((sourceFromQuery === 'met' || sourceFromCookies === 'met') && vectorGtagHotel) {
      store.commit('SET_VECTOR_GTAG_USER', vectorGtagHotel)

      gtagConfig.includes.push({
        id: `AW-${vectorGtagHotel.GOOGLE_CONVERSION_ID}`,
        params: {
          send_page_view: false
        }
      })

      // set source flag
      $cookies.set('source', 'met', { expires: oneHour })
    }

    Vue.use(VueGtag,
      gtagConfig,
      router
    )

    new Vue({
      i18n,
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  })
  .catch(err => {
    console.error(err)
    new Vue({
      store,
      render: h => h(NotFound)
    }).$mount('#app')
  })
