import { render, staticRenderFns } from "./restrictTip.vue?vue&type=template&id=29d62e3b&"
import script from "./restrictTip.vue?vue&type=script&lang=js&"
export * from "./restrictTip.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src736759391/src/codestar-connections.ap-northeast-1.amazonaws.com/git-http/364953906322/ap-northeast-1/84d36ee7-16b8-4e80-bff0-7e6a249efe8b/OwlTing/owlnest_booking_engine/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('29d62e3b', component.options)
    } else {
      api.reload('29d62e3b', component.options)
    }
    module.hot.accept("./restrictTip.vue?vue&type=template&id=29d62e3b&", function () {
      api.rerender('29d62e3b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Hotel/components/restrictTip.vue"
export default component.exports