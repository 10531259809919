const pixelHotels = [
  {
    hotelId: 1077,
    hotelName: '南庄雲水度假森林',
    pixelId: '831496051050047'
  },
  {
    hotelId: 1113,
    hotelName: '噗嚨共-花蓮壽豐民宿Pulongon BNB',
    pixelId: '390752971757218'
  },
  {
    hotelId: 2040,
    hotelName: 'A Moment',
    pixelId: '400911880564063'
  },
  {
    hotelId: 2386,
    hotelName: 'Wake Up In Homestay_吉隆坡民宿',
    pixelId: '700313940464678'
  },
  {
    hotelId: 2985,
    hotelName: '墾丁戀戀星辰湖畔莊園',
    pixelId: '1932712030249551'
  },
  {
    hotelId: 3265,
    hotelName: '寗夏印象',
    pixelId: '2604428512990764'
  },
  {
    hotelId: 3607,
    hotelName: '沙連堡旅館',
    pixelId: '2659793504321610'
  },
  {
    hotelId: 4000,
    hotelName: '戀戀星辰湖畔莊園-星光館',
    pixelId: '609626527574892'
  },
  {
    hotelId: 4329,
    hotelName: '美好境界',
    pixelId: '3172651866318313'
  },
  {
    hotelId: 4509,
    hotelName: '水里里山村森態旅館',
    pixelId: '7375860112466770'
  },
  {
    hotelId: 4891,
    hotelName: '峇里峇里休閒渡假民宿',
    pixelId: '629469764558423'
  },
  {
    hotelId: 5029,
    hotelName: '森山谷 中寮森林營地',
    pixelId: '7511760638907993'
  },
  {
    hotelId: 5063,
    hotelName: '天下居汽車行館',
    pixelId: '455656877429555'
  },
  {
    hotelId: 5931,
    hotelName: '鷗漫景觀農場露營',
    pixelId: '1013500580141572'
  },
  {
    hotelId: 6026,
    hotelName: '海野民宿',
    pixelId: '308110595708295'
  },
  {
    hotelId: 6047,
    hotelName: '美好覓静',
    pixelId: '432306612983846'
  }
]

export default pixelHotels
