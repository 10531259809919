const gaHotels = [
  {
    id: 593,
    hotelName: '夾腳拖的家',
    gaIds: ['G-QKQ2G6MH1Z']
  },
  {
    id: 868,
    hotelName: '貝拉森林鄉村民宿',
    gaIds: ['GTM-PNJFRL84']
  },
  {
    id: 910,
    hotelName: '香榭雅筑',
    gaIds: ['AW-981922039', 'UA-228152444-1'],
    conversionIds: ['AW-981922039/VVYiCJm1q7MDEPfhm9QD']
  },
  {
    id: 1077,
    hotelName: '南庄雲水度假森林',
    gaIds: ['UA-142865664-4']
  },
  {
    id: 1237,
    hotelName: '山中夢遊',
    gaIds: ['G-QKQ2G6MH1Z']
  },
  {
    id: 2420,
    hotelName: '期遇澎湖',
    gaIds: ['AW-10854953142'],
    conversionIds: ['AW-10854953142/it6-CPuTvaMDELbhhbgo']
  },
  {
    id: 2952,
    hotelName: '鹽花',
    gaIds: ['G-QKQ2G6MH1Z']
  },
  {
    id: 2985,
    hotelName: '墾丁戀戀星辰湖畔莊園',
    gaIds: ['G-2G85K8X8YV']
  },
  {
    id: 3116,
    hotelName: '台灣烏托邦國際青年旅舍 Utopia_Hostel_Taiwan',
    gaIds: ['UA-176268433-2']
  },
  {
    id: 3273,
    hotelName: '衿日林民宿',
    gaIds: ['UA-107070202-1']
  },
  {
    id: 3336,
    hotelName: '海境渡假民宿',
    gaIds: ['AW-16551789980']
  },
  {
    id: 3507,
    hotelName: '少冰半糖',
    gaIds: ['UA-186095265-1']
  },
  {
    id: 3565,
    hotelName: 'IF商務汽旅',
    gaIds: ['UA-153520389-1', 'G-53S3V5G0MN']
  },
  {
    id: 3614,
    hotelName: '安蘭居國際青年館',
    gaIds: ['G-5W2X5H366Q', 'GTM-KS2K46L']
  },
  {
    id: 3900,
    hotelName: '棉花糖民宿',
    gaIds: ['G-B19MEFFX7F']
  },
  {
    id: 4000,
    hotelName: '戀戀星辰湖畔莊園-星光館',
    gaIds: ['G-9GKKB5MTFE']
  },
  {
    id: 4039,
    hotelName: '斑馬芭芭菈',
    gaIds: ['G-Q0JKHZ9FG4']
  },
  {
    id: 4040,
    hotelName: '嗨斑馬輕旅行',
    gaIds: ['G-CK1QMV3G61']
  },
  {
    id: 4111,
    hotelName: '棚棚屋 小空間住藝術',
    gaIds: ['UA-223829068-1', 'AW-11164127583']
  },
  {
    id: 4154,
    hotelName: 'Party Me 派對蜜民宿',
    gaIds: ['G-SR885XJ4FZ']
  },
  {
    id: 4158,
    hotelName: 'Bellus Inn',
    gaIds: ['AW-10973500914'],
    conversionIds: ['AW-10973500914/gh2lCMme--MDEPKryfAo']
  },
  {
    id: 4191,
    hotelName: '椰林會館',
    gaIds: ['G-3V4Z15SE6C']
  },
  {
    id: 4329,
    hotelName: '美好境界',
    gaIds: ['G-038868DD2D']
  },
  {
    id: 4509,
    hotelName: '水里里山村森態旅館',
    gaIds: ['G-E77Y112R2P']
  },
  {
    id: 4544,
    hotelName: '耿毛一家民宿',
    gaIds: ['G-T2ZQGXFLB7']
  },
  {
    id: 4618,
    hotelName: '日央商旅',
    gaIds: ['AW-457672868'],
    conversionIds: ['AW-457672868/X_WrCJ_s8t0DEKSRntoB']
  },
  {
    id: 4788,
    hotelName: '鬧鐘關掉 Alarm Off',
    gaIds: ['GTM-P8GSTW6']
  },
  {
    id: 4797,
    hotelName: '森之秘湯',
    gaIds: ['GTM-K9GLZDK2']
  },
  {
    id: 4891,
    hotelName: '峇里峇里休閒渡假民宿',
    gaIds: ['AW-443950632', 'UA-102761457-1', 'G-CNH28CB1YX'],
    conversionIds: ['AW-443950632/UpfpCOnEm4sYEKjM2NMB']
  },
  {
    id: 4994,
    hotelName: '南灣有風 包棟民宿',
    gaIds: ['AW-11107531244', 'G-0C6JZGFWEW']
  },
  {
    id: 5063,
    hotelName: '天下居汽車行館',
    gaIds: ['AW-981657101'],
    conversionIds: ['AW-981657101/5G85CPuC05oYEI3Mi9QD']
  },
  {
    id: 5696,
    hotelName: '馬堡莊園民宿',
    gaIds: ['G-KXQHVD4KG7']
  },
  {
    id: 5881,
    hotelName: '風箏小木屋',
    gaIds: ['G-B5NHJFHS2B']
  },
  {
    id: 6047,
    hotelName: '美好覓静',
    gaIds: ['G-MYMTTYZZ5F']
  },
  {
    id: 6158,
    hotelName: '離海5min b&b',
    gaIds: ['AW-10813161227']
  }
]

export default gaHotels
