const vectorGtagList = [
  {
    id: 2210,
    hotelName: '鹿旅民宿',
    GOOGLE_CONVERSION_ID: '10789581664',
    GOOGLE_CONVERSION_LABEL: '8WbSCO-64YQDEODm75go'
  },
  {
    id: 3273,
    hotelName: '衿日林民宿',
    GOOGLE_CONVERSION_ID: '10789581256',
    GOOGLE_CONVERSION_LABEL: 'j6JHCLfN4YQDEMjj75go'
  },
  {
    id: 3891,
    hotelName: '松楓親子民宿',
    GOOGLE_CONVERSION_ID: '10817609617',
    GOOGLE_CONVERSION_LABEL: 'RqvlCKCvpIcDEJG_nqYo'
  }
]

export default vectorGtagList
